import React, { Component } from 'react';
import Landing from './landing.component';
import LighthouseNavbar from './lighthouse-navbar.component';
import Footer from './footer.component';

import '../styles/base.css';

class Lighthouse extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        document.title = 'Lighthouse';
        document.getElementById("favicon").href = process.env.PUBLIC_URL + "/lighthouse-favicon.ico";
        document.getElementById("favicon-apple").href = process.env.PUBLIC_URL + "/lighthouse-logo192.png";
    }


    render() {
        return (
            <div>
                <LighthouseNavbar />
                <Landing />
                <Footer/>
            </div>

        )
    }
}

export default Lighthouse;
