import React, { Component } from 'react';
import Hero from './hero.component';
import LandingDetail from './landing-detail.component';
import '../styles/landing.css';

/**
 * landing page component. redirect to sign up
 */
class Landing extends Component {
    constructor(props) {
        super(props);


    }



    render() {
        return (

            <div>
                <div className="hero text-center">
                    <Hero/>
                </div>
                <h1 id="details">hi</h1>
                <div className="landing-detail pt-4">
                    <LandingDetail/>
                </div>
            </div>
        )
    }
}

export default Landing;
