import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import icon from '../assets/icon.png';

import '../styles/navbar.css'

class LighthouseNavbar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Navbar className="transparent-style sticky-nav navbar">
                <Navbar.Brand href="/lighthouse">
                    <img src={icon}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />
                    Lighthouse
                </Navbar.Brand>
            </Navbar>
        )
    }
}
export default LighthouseNavbar;
