import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PageNotFound from './lighthouse/components/page-not-found.component';


import Lighthouse from './lighthouse/components/lighthouse.component';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

/**
 * defines routes in the web app, also with navbar and footer
 */
class App extends Component {

  render() {
    return (
      <div className="App">
        <Router basename="/">
            <Switch>
              <Route exact path="/lighthouse" component={Lighthouse} />
              <Route path="/" component={PageNotFound} />
            </Switch>
            
        </Router>
      </div>
    );
  }
}

export default App;
