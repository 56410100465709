import React, { Component } from 'react';
import { SegmentedControl } from 'segmented-control';
import { Container, Row, Col, Image } from 'react-bootstrap';

import pay from '../assets/pay.png';
import order from '../assets/order.png';
import reward from '../assets/reward.png';

class LandingDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            segment: "order",
        }

        this.handleChange = this.handleSegmentChange.bind(this);

    }

    getDetails() {
        let details = {
            "pay": {
                img: pay,
                caption: "Pay",
                text: "When the customers are ready to pay, they can do so easily within the Lighthouse app. With options to add tips or order to-go boxes, the checkout process is completly cashless and automated leaving little room for error.",
            },
            "order": {
                img: order,
                caption: "Order",
                text: "Lighthouse is an end-to-end mobile point of sale (mPoS) system that enables frictionless interaction between merchants and customers. In a restaurant setting, using the Lighthouse app, the customer can view the menu and place an order without being attended to. This allows the restaurants to better utilize thier staff, and streamline their operation.",
            },
            "reward": {
                img: reward,
                caption: "Rewards",
                text: "After each visit, rewards points are collected automatically within the Lighthouse app to be redeemed the next time the customer visits the store or restaurant. Having a rewards program not only attracts returning customers, but also offers creative new ways to promote their business. With our tool suite, merchants can customized their promitions and push them directly to the customers' device.",
            }
        }
        return (details[this.state.segment])
    }

    handleSegmentChange(selection) {
        this.setState({
            segment: selection
        })
        console.log(selection);
    }

    render() {
        return (
            <Container>
                <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                        <SegmentedControl
                            className="SegmentedCtl transparent-style"
                            name="SegmentedCtl"
                            options={[
                                { label: "Order", value: "order", default: true },
                                { label: "Pay", value: "pay" },
                                { label: "Rewards", value: "reward" },
                            ]}
                            setValue={newValue => this.handleSegmentChange(newValue)}
                            style={{ width: '100%', color: 'white' }}
                        />
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    <Col sm={12} md={6}>
                        <Image className="detail-img" src={this.getDetails().img} />
                    </Col>
                    <Col>
                        <h1 className="detail-caption pl-2">{this.getDetails().caption}</h1>
                        <p className="detail-text pl-2 pr-2">{this.getDetails().text}</p>
                    </Col>
                </Row>





            </Container>
        )
    }
}

export default LandingDetail;
