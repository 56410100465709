import React, { Component } from 'react';
import { Jumbotron, Button } from 'react-bootstrap';
import lighthouse from '../assets/lighthouse.jpg';


class Hero extends Component {
    constructor(props) {
        super(props);

    }
    

    render() {

        const heroStyle = {
            color: 'white',
            backgroundImage: 'url(' + lighthouse + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            height: '100%',
            borderRadius: '0px',
          };

        return (
            <Jumbotron className="hero-jumbo" style={heroStyle}>
                <h1>Lighthouse Pay</h1>
                <p>
                    Digital wallet for the mobile age.
                    </p>
                <p>
                    <Button variant="outline-light" href="#details">Learn more</Button>
                </p>    
            </Jumbotron>
            
        )
    }
}

export default Hero;
